import WhatsappFAB from '@web/atomic/atm.whatsapp-fab/whatsapp-fab.component';
import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import OrderSuccess from '@lp-root/src/modules/order-success/order-success.component';
import { PageProps } from '@lp-src/utils/local-types';
import { Body, H3 } from '@web/atomic';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import * as React from 'react';

const CompraRealizadaPage: React.FunctionComponent<PageProps> = (props) => {
  const socialMedia = {
    canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
    title: 'Sucesso! Estamos processando seu pedido!',
    description: 'Assim que o pagamento for identificado, o programa completo será disponibilizado para você.',
  };
  return (
    <>
      <SEO socialMedia={socialMedia} />

      <OrderSuccess headline={'Começa agora o fim dos seus problemas com o sono!'}>
        <H3>
          Sucesso{' '}
          <span role="img" aria-label="Festa">
            {' '}
            🎉{' '}
          </span>{' '}
          ! Estamos processando seu pedido!
        </H3>
        <Body>
          Assim que o pagamento for identificado no nosso sistema, iremos liberar todo o programa para você! Se você já começou o programa,
          é só aguardar, caso contrário leia os próximos passos abaixo.
        </Body>
      </OrderSuccess>
      <WhatsappFAB kind="support" />
    </>
  );
};

export default CompraRealizadaPage;

export const query = graphql`
  query CompraRealizadaQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
